const {useEffect} = require("react");
const {useAuth} = require("../../providers/AuthProvider");

export default function Logout()
{
    const {logout} = useAuth();

    useEffect(() => logout(), []);

    return (
        <></>
    )
}