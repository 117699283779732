import { Suspense } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

const LoaderWrapper = styled('div')({
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 2001,
    width: '100%'
});

const LoadLine = () => {
    return (
        <LoaderWrapper>
            <LinearProgress/>
        </LoaderWrapper>
    )
}

const Loading = (Component) => (props) => {
    return (
        <Suspense fallback={<LoadLine />}>
            <Component {...props} />
        </Suspense>
    )
}

export default Loading;