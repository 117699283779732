import { useRoutes} from 'react-router-dom';
import {lazy} from 'react';

import AuthLayout from './layout/AuthLayout'

import Loading from "./components/Loader";
import {Protected} from "./components/Protected";
import DashboardLayout from "./layout/DashboardLayout";
import Logout from "./views/auth/Logout";

const LoadComponent = (name, ns) => {
    return Loading(lazy(() => import(`./views/${ns}/${name}`).then(({default : name}) => ({default: name}))));
}

//auth pages
const Login = LoadComponent('Login', 'auth');
const Register = LoadComponent('Register','auth');
const Recovery = LoadComponent('Recovery','auth');

//dashboard pages
const Support = LoadComponent('Support', 'dashboard');
const Config = LoadComponent('Config', 'dashboard');
const CarControl = LoadComponent('CarControl', 'dashboard');
const CarList = LoadComponent('CarList', 'dashboard');
const Monitoring = LoadComponent('Monitoring', 'dashboard');

const AuthRoutes = {
    path: "/",
    element: <AuthLayout/>,
    children: [
        {
            'path': '',
            'element': <Login/>
        },
        {
            'path': 'login',
            'element': <Login/>
        },
        {
            'path': 'register',
            'element': <Register/>
        },
        {
            'path': 'recovery',
            'element': <Recovery/>
        },
        {
            'path': 'logout',
            'element': <Logout/>
        }
    ]
}

const DashBoardRoutes = {
    path: "/dashboard",
    element: <Protected>
        <DashboardLayout/>
    </Protected>,
    children: [
        {
            'path': '',
            'element': <Monitoring/>
        },
        {
            'path': 'support',
            'element': <Support/>
        },
        {
            'path': 'config',
            'element': <Config/>
        },
        {
            'path': 'cars/new',
            'element': <CarControl/>
        },
        {
            'path': 'cars/list',
            'element': <CarList/>
        }
    ]
}

export const Routes = () => useRoutes([AuthRoutes, DashBoardRoutes]);