import {useAuth} from "../providers/AuthProvider";
import {Navigate} from "react-router-dom";

export const Protected = ({ children }) => {
    const {token} = useAuth();

    if (!token) {
        return <Navigate to="/login" replace />;
    }

    return children;
};