import {ThemeProvider } from '@mui/material/styles';
import {createTheme, CssBaseline} from '@mui/material';
import {BrowserRouter} from 'react-router-dom';
import TimeAgo from "javascript-time-ago";
import ru from "javascript-time-ago/locale/ru";
import "./i18n_config";

import {Routes} from "./routes";
import {AuthProvider} from "./providers/AuthProvider";

TimeAgo.addDefaultLocale(ru);

export default function App()
{
    const theme = createTheme();

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <BrowserRouter>
                <AuthProvider>
                    <Routes/>
                </AuthProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
}