import React from 'react';
import { Outlet } from 'react-router-dom';
import {Box, Container} from "@mui/material";
import Copyright from "../components/Copyright";

export default function AuthLayout()
{
    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Outlet/>
            </Box>
            <Copyright sx={{mt: 8, mb: 4}}/>
        </Container>
    );
}