import { useState } from "react";

export const useStorage = (key, value) => {
    const [currentValue, setCurrentValue] = useState(() => {
        try {
            const extractedValue = window.localStorage.getItem(key);

            if (extractedValue)
            {
                return JSON.parse(extractedValue);
            } else {
                window.localStorage.setItem(key, JSON.stringify(value));
                return value;
            }

        } catch (error) {
            return value;
        }
    });

    const setValue = (v) => {
        try {
            window.localStorage.setItem(key, JSON.stringify(v));
        } catch (error) {
            //
        }

        setCurrentValue(v);
    };

    return [currentValue, setValue];
}