import {createContext, useContext} from "react";
import {useStorage} from "../hooks/useStorage";
import {useNavigate} from "react-router-dom";

const AuthContext = createContext(null);

export const AuthProvider = ({children}) => {
    const [token, setToken] = useStorage("token", null);
    const navigate = useNavigate();

    const login = async t => {
        setToken(t);
        navigate("/dashboard");
    }
    const logout = () => {
        setToken(null);
        navigate("/login");
    }

    const value = {
        token,
        login,
        logout
    }

    return <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>;
}

export const useAuth = () => {
    return useContext(AuthContext);
};