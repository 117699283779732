import * as React from 'react';
import styled from "@emotion/styled";
import MuiAppBar from '@mui/material/AppBar';
import {useTranslation} from "react-i18next";
import {useTheme} from "@emotion/react";
import MenuIcon from '@mui/icons-material/Menu';
import {Link as RouterLink, Outlet} from "react-router-dom";
import ExploreIcon from '@mui/icons-material/Explore';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SettingsIcon from '@mui/icons-material/Settings';
import AddIcon from '@mui/icons-material/Add';
import {
    Support, Logout
} from '@mui/icons-material';
import List from '@mui/material/List';
import Box from "@mui/material/Box";
import {Divider, Drawer, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar} from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Copyright from "../components/Copyright";

const drawerWidth = 260;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function DashboardLayout() {
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const {t} = useTranslation("dashboard-menu");

    const menu = [
        {"ico": <ExploreIcon/>, "label": t("dashboard-label"), "to": "/dashboard"},
        {"ico": <LocalShippingIcon/>, "label": t("cars-label"), "to": "/dashboard/cars/list"},
        {"ico": <AddIcon/>, "label": t("cars-label-add"), "to": "/dashboard/cars/new"},
        {"ico": <SettingsIcon/>, "label": t("config-label"), "to": "/dashboard/config"},
        {"ico": <Support/>, "label": t("support-label"), "to": "/dashboard/support"},
        {"ico": <Logout/>, "label": t("exit-label"), "to":"/logout"},
    ];

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h4" noWrap component="div">
                        Transport Tracker
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                   <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {menu.map((item, index) => (
                        <ListItem key={index} disablePadding>
                            <ListItemButton component={RouterLink} to={item.to}>
                                <ListItemIcon>
                                    {item.ico}
                                </ListItemIcon>
                                <ListItemText primary={item.label}/>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <Main open={open} maxwidth="lg" sx={{ height:"100vh", backgroundColor:theme.palette.grey[100] }} >
                <DrawerHeader />
                <Outlet/>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Main>

        </Box>
    );
}